import React, { useEffect, useRef } from 'react';
import styles from './soundcloud.module.scss';

declare global {
  interface Window {
    SC: any;
  }
}

interface SoundcloudProps {
  trackUrl: string;
}

const Soundcloud: React.FC<SoundcloudProps> = ({ trackUrl }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const widgetRef = useRef<any>(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    const widget = window.SC.Widget(iframe);
    widgetRef.current = widget;

    widget.bind(window.SC.Widget.Events.READY, () => {
      console.log('Widget is ready');
    });

    widget.bind(window.SC.Widget.Events.PLAY, () => {
      console.log('Track is playing');
    
    });

    widget.bind(window.SC.Widget.Events.PAUSE, () => {
      console.log('Track is paused');
    });

    widget.bind(window.SC.Widget.Events.FINISH, () => {
      console.log('Track finished playing');
    });

    return () => {
      widget.unbind(window.SC.Widget.Events.READY);
      widget.unbind(window.SC.Widget.Events.PLAY);
      widget.unbind(window.SC.Widget.Events.PAUSE);
      widget.unbind(window.SC.Widget.Events.FINISH);
    };
  }, []);

  useEffect(() => {
    if (widgetRef.current) {
      widgetRef.current.load(trackUrl, {
        auto_play: false,
        buying: false,
        liking: false,
        download: false,
        sharing: false,
        show_artwork: true,
        show_comments: false,
        show_playcount: false,
        show_user: true,
        hide_related: true,
        visual: true,
        start_track: 0,
      });
    }
  }, [trackUrl]);

  return (
    <div className={styles.soundcloudContainer}>
      <iframe
        ref={iframeRef}
        title="SoundCloud Player"
        width="100%"
        height="100%"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src={`https://w.soundcloud.com/player/?url=${encodeURIComponent(trackUrl)}`}
        className={styles.soundcloudIframe}
      />
    </div>
  );
};


export default Soundcloud;