import React, { useEffect, useRef } from 'react';
import styles from './spotifyview.module.scss';

declare global {
    interface Window {
      onSpotifyIframeApiReady: (IFrameAPI: any) => void;
    }
  }
interface SpotifyViewProps {

    spotifyUri: string;
  }

  const SpotifyView: React.FC<SpotifyViewProps> = ({ spotifyUri }) => {
  const spotifyRef = useRef<HTMLDivElement>(null);
  const embedController = useRef<any>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://open.spotify.com/embed/iframe-api/v1';
    script.async = true;
    document.body.appendChild(script);

    window.onSpotifyIframeApiReady = (IFrameAPI: any) => {
      const element = spotifyRef.current;
      const options = {
        uri: spotifyUri,
        width: '100%',
        height: '80%'
      };
      const callback = (EmbedController: any) => {
        embedController.current = EmbedController;
      };
      IFrameAPI.createController(element, options, callback);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [spotifyUri]);

  useEffect(() => {
    if (embedController.current) {
      embedController.current.addListener('playback_update', (e: any) => {
        // Extract audio features or waveform data from the playback update event
        // and pass it to the onAudioData callback
        // Example: onAudioData(e.data.waveform);
      });
    }
  }, []);

  return <div ref={spotifyRef} className={styles.spotifyContainer}></div>;
};

export default SpotifyView;