import Firework from './components/firework/firework';
import styles from './App.module.scss';
import '@fontsource/space-grotesk';
import Soundcloud from './components/soundcloud/soundcloud';
import SpotifyView from './components/spotifyview/spotifyview';
import React, { useState, useEffect } from 'react'
import instagramIcon from './assets/insta.png';
import spotifyIcon from './assets/spotify.png';
import soundcloudIcon from './assets/soundcloud.png';
import mixcloudIcon from './assets/mixcloud.png';
import FireworkComponent from './components/firework/firework';


const App = () => {
    
  
    return (
      
        <div className={styles.App}>
            <div className={styles['firework-wrapper']}>
                <Firework />
            </div>
            <div className={styles['overlay-content']}>
                <div className={styles['grid-container']}>

                    <div className={styles['grid-item']}>
                        <h1>KESKESAY</h1>
                        <h3>Newcastle and London based producer making electronic music from micro house to ambient electronic tracks.</h3>
                        
                        <div className={styles['row']}>

                            <div className={styles['icon']}>
                            <a href="https://www.instagram.com/keskesay_/" target="_blank" rel="noopener noreferrer">
                            <img src={instagramIcon} alt="Instagram" className={styles['instagram-icon']} width={30} />
                            </a>
                            </div>

                            <div className={styles['icon']}>
                            <a href="https://open.spotify.com/artist/04VeE9VBQoLtvFV4BAq32Y?go=1&sp_cid=86dafdefbbcb18139e9980f88cc2745c&nd=1&dlsi=19df238346114238" target="_blank" rel="noopener noreferrer">
                            <img src={spotifyIcon} alt="Spotify" className={styles['instagram-icon']} width={30} />
                            </a>
                            </div>

                            <div className={styles['icon']}>
                            <a href="https://soundcloud.com/user-216694930" target="_blank" rel="noopener noreferrer">
                            <img src={soundcloudIcon} alt="Soundcloud" className={styles['instagram-icon']} width={30} />
                            </a>
                            </div>
                            


                        </div>
                        

                    </div>


                    <div className={styles['grid-item']}>

                        

                    <div className={styles['mixcloud-container']}>

                    
                                    <iframe width="100%" height="120" src="https://player-widget.mixcloud.com/widget/iframe/?hide_cover=1&feed=%2Fbodaciousfm%2Fbodaciousfm-20231206-162355%2F" ></iframe>
                                    <div className={styles['row']}>
                                    <div className={styles['bodacious-icon']}>
                                        <a href="https://www.mixcloud.com/bodaciousfm/" target="_blank" rel="noopener noreferrer">
                                        <img src={mixcloudIcon} alt="Mixcloud" className={styles['bodacious-img']} width={30} />
                                        </a>
                                    </div>
                                    <div className={styles['bodacious-text']}>
                                        <h3>    Resident host on BodaciousFM</h3>
                                        </div>
                                        
                                    
                                    
                                    </div>

                    </div>

                    
                    </div>

                    

                    <div className={styles['grid-item']}>
                        <div className={styles['flexbox-container']}>




                            <Soundcloud
                                trackUrl="https://soundcloud.com/user-216694930/things-fall-apart"

                            />
                            <Soundcloud
                                trackUrl="https://soundcloud.com/user-216694930/on-life-edit-newest-1st-feb-2nd"

                            />
                            <Soundcloud
                                trackUrl="https://soundcloud.com/user-216694930/squeky-shit-2?si=c0a6d24a3c7f4c6596a2a028c926efac&utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing"

                            />

                            

                        </div>
                    </div>
                    <div className={styles['grid-item']}>
                        <SpotifyView
                                spotifyUri="spotify:artist:04VeE9VBQoLtvFV4BAq32Y"
                            />
                        </div>
                </div>
            </div>
        </div>
    );
};

export default App;