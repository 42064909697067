import { useEffect, useRef } from 'react';

// Coefficients and parameters
const NUM_PARTICLES = 70;
const PARTICLE_SIZE = 0.5;
const PARTICLE_LIFESPAN = 1000;
const PARTICLE_FADE_RATE = 0.0005;
const PARTICLE_SPEED_LIMIT = 0.5;
const TAIL_LENGTH = 1000;
const SEPARATION_DISTANCE = 20;
const ALIGNMENT_DISTANCE = 50;
const COHESION_DISTANCE = 50;
const SEPARATION_STRENGTH = 0.05;
const ALIGNMENT_STRENGTH = 0.01;
const COHESION_STRENGTH = 0.01;

// Particle Class
class Particle {
  x: number;
  y: number;
  xVol: number;
  yVol: number;
  alpha: number;
  lifespan: number;
  R: number;
  G: number;
  B: number;
  canvas: HTMLCanvasElement | null;
  tail: { x: number; y: number }[];

  constructor(x: number, y: number, R: number, G: number, B: number, canvas: HTMLCanvasElement | null) {
    this.x = x;
    this.y = y;
    this.xVol = (Math.random() - 0.5) * PARTICLE_SPEED_LIMIT;
    this.yVol = (Math.random() - 0.5) * PARTICLE_SPEED_LIMIT;
    this.alpha = 1;
    this.lifespan = PARTICLE_LIFESPAN;
    this.R = R;
    this.G = G;
    this.B = B;
    this.canvas = canvas;
    this.tail = [];
  }

  draw() {
    if (!this.canvas) {
      return;
    }

    const ctx = this.canvas.getContext('2d');

    if (ctx) {
      ctx.save();
      ctx.globalAlpha = this.alpha;
      ctx.fillStyle = `rgba(${this.R},${this.G},${this.B})`;
      ctx.beginPath();
      ctx.arc(this.x, this.y, PARTICLE_SIZE, 0, Math.PI * 2, false);
      ctx.fill();

      // Draw particle tail
      ctx.strokeStyle = `rgba(${this.R},${this.G},${this.B},${this.alpha})`;
      ctx.lineWidth = PARTICLE_SIZE;
      ctx.beginPath();
      ctx.moveTo(this.x, this.y);
      this.tail.forEach((segment) => {
        ctx.lineTo(segment.x, segment.y);
      });
      ctx.stroke();

      ctx.restore();
    }
  }

  update(particles: Particle[]) {
    const separation = this.separate(particles);
    const alignment = this.align(particles);
    const cohesion = this.cohere(particles);


    this.xVol += separation.x * SEPARATION_STRENGTH + alignment.x * ALIGNMENT_STRENGTH + cohesion.x * COHESION_STRENGTH;
    this.yVol += separation.y * SEPARATION_STRENGTH + alignment.y * ALIGNMENT_STRENGTH + cohesion.y * COHESION_STRENGTH;

    this.limitVelocity();

    this.x += this.xVol;
    this.y += this.yVol;
    this.alpha -= PARTICLE_FADE_RATE;
    this.lifespan--;

    // Update particle tail
    this.tail.unshift({ x: this.x, y: this.y });
    if (this.tail.length > TAIL_LENGTH) {
      this.tail.pop();
    }
  }

  separate(particles: Particle[]) {
    let steer = { x: 0, y: 0 };
    let count = 0;

    particles.forEach((particle) => {
      const distance = this.distance(this.x, this.y, particle.x, particle.y);
      if (distance > 0 && distance < SEPARATION_DISTANCE) {
        const diff = { x: this.x - particle.x, y: this.y - particle.y };
        diff.x /= distance;
        diff.y /= distance;
        steer.x += diff.x;
        steer.y += diff.y;
        count++;
      }
    });

    if (count > 0) {
      steer.x /= count;
      steer.y /= count;
    }

    return steer;
  }

  align(particles: Particle[]) {
    let sum = { x: 0, y: 0 };
    let count = 0;

    particles.forEach((particle) => {
      const distance = this.distance(this.x, this.y, particle.x, particle.y);
      if (distance > 0 && distance < ALIGNMENT_DISTANCE) {
        sum.x += particle.xVol;
        sum.y += particle.yVol;
        count++;
      }
    });

    if (count > 0) {
      sum.x /= count;
      sum.y /= count;
    }

    return sum;
  }

  cohere(particles: Particle[]) {
    let sum = { x: 0, y: 0 };
    let count = 0;

    particles.forEach((particle) => {
      const distance = this.distance(this.x, this.y, particle.x, particle.y);
      if (distance > 0 && distance < COHESION_DISTANCE) {
        sum.x += particle.x;
        sum.y += particle.y;
        count++;
      }
    });

    if (count > 0) {
      sum.x /= count;
      sum.y /= count;
      return this.seek(sum);
    }

    return sum;
  }

  seek(target: { x: number; y: number }) {
    const desired = { x: target.x - this.x, y: target.y - this.y };
    const distance = Math.sqrt(desired.x * desired.x + desired.y * desired.y);
    desired.x /= distance;
    desired.y /= distance;
    return desired;
  }

  distance(x1: number, y1: number, x2: number, y2: number) {
    const dx = x1 - x2;
    const dy = y1 - y2;
    return Math.sqrt(dx * dx + dy * dy);
  }

  limitVelocity() {
    const speed = Math.sqrt(this.xVol * this.xVol + this.yVol * this.yVol);
    if (speed > PARTICLE_SPEED_LIMIT) {
      this.xVol = (this.xVol / speed) * PARTICLE_SPEED_LIMIT;
      this.yVol = (this.yVol / speed) * PARTICLE_SPEED_LIMIT;
    }
  }

  
}

// FireworkComponent
const FireworkComponent = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const particleArray = useRef<Particle[]>([]);

  useEffect(() => {
    const handleResize = () => {
      if (canvasRef.current) {
        canvasRef.current.width = window.innerWidth;
        canvasRef.current.height = window.innerHeight;
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    // Add initial particles
    for (let i = 0; i < NUM_PARTICLES; i++) {
      const x = Math.random() * (canvasRef.current ? canvasRef.current.width : 0);
      const y = Math.random() * (canvasRef.current ? canvasRef.current.height : 0);
      const R = 94;
      const G = 78;
      const B = 28;
      particleArray.current.push(new Particle(x, y, R, G, B, canvasRef.current));
    }

    const animate = () => {
      requestAnimationFrame(animate);

      if (!canvasRef.current) {
        return;
      }

      const ctx = canvasRef.current.getContext("2d");

      if (ctx) {
        ctx.fillStyle = "rgba(0,0,0,0.1)";
        ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }

      // Update particle positions based on boid rules and audio data
      particleArray.current.forEach((particle, index) => {
        particle.update(particleArray.current);
        particle.draw();

        if (particle.lifespan <= 0 || particle.alpha <= 0) {
          particleArray.current.splice(index, 1);
        }
      });

      // Add new particles
      if (particleArray.current.length < NUM_PARTICLES) {
        const x = Math.random() * (canvasRef.current ? canvasRef.current.width : 0);
        const y = Math.random() * (canvasRef.current ? canvasRef.current.height : 0);
        const R = 194;
        const G = 178;
        const B = 128;
        particleArray.current.push(new Particle(x, y, R, G, B, canvasRef.current));
      }
    };

    animate();

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <canvas ref={canvasRef} id="canvas"></canvas>;
};

export default FireworkComponent;